import { Helmet } from "react-helmet";

function IAMLogo() {
  const containerStyle = {
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  };

  const logoStyle = {
    width: "300px",
    height: "200px",
  };

  const logoSvgStyle = {
    width: "100%",
    height: "100%",
  };

  const orbitronItem = {
    fontOpticalSizing: "auto",
    fontWeight: <weight />,
    fontStyle: "normal",
  };

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div style={containerStyle}>
        <div style={logoStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            style={logoSvgStyle}
          >
            <rect x="3" y="3" width="17.5" height="6" rx="1" ry="1" fill="none" />

            <text
              x="3"
              y="8"
              fill="#d5d5e3"
              fontSize="3"
              fontFamily="Orbitron "
              fontWeight="500"
              style={orbitronItem}
            >
              guard
            </text>
            <text
              x="14"
              y="8"
              fill="#d5d5e3"
              fontSize="3"
              fontFamily="Orbitron "
              fontWeight="500"
              style={orbitronItem}
            >
              iam
            </text>

            <rect x="4" y="6.2" width="2" height="2" fill="none" />

            <path
              d="M8 4l4 2-4 2-4-2 4-2z"
              transform="scale(0.2) translate(63.8, 22.3)"
              fill="#d5d5e3"
            />
            <path
              d="M8 2l4 2-4 2-4-2 4-2z"
              transform="scale(0.2) translate(63.8, 22.2)"
              fill="#ff0000"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IAMLogo;
